


























import Vue from 'vue';
import { Component, Inject, InjectReactive } from 'vue-property-decorator';
import Page from '@/components/Page.vue';
import { Course } from '@/models/entities/Course';
import LessonsList from '@/views/courses/components/LessonsList.vue';
import { Lesson } from '@/models/entities/Lesson';

@Component({
  components: {
    LessonsList,
    Page,
  },
})
export default class CourseScheduleView extends Vue {
  @InjectReactive() course!: Course;
  selectedLessonId?: number = 0;

  @Inject() isCourseAdmin!: () => boolean;

  handleUpdateLesson(lesson: Lesson) {
    this.selectedLessonId = lesson.lessonId;
    this.course.lessons.push(lesson);
  }

  handleDialogClose() {
    this.$router.push({
      name: 'course-schedule',
      params: { id: String(this.course.courseId) },
    });
  }
}
